import { ReceiptStatus } from '../models/enums/ReceiptStatus';
import { Receipt } from '../models/receipt/Receipt';
import { BigNumber } from 'bignumber.js';

export class UncommittedCalculator {
    private static readonly STATUSES_TO_INCLUDE: ReceiptStatus[] = [
        ReceiptStatus.POSTED,
        ReceiptStatus.PENDING_POST,
        ReceiptStatus.POST_FAILURE,
        ReceiptStatus.VOID_FAILURE,
    ];
    public calculateAmount(receipts: Receipt[], lineAmount: number): number {
        return this.calculateUncommittedAmount(receipts, lineAmount);
    }
    private calculateUncommittedAmount(receipts: Receipt[], lineAmount: number): number {
        const validReceipts = receipts.filter((receipt) =>
            UncommittedCalculator.STATUSES_TO_INCLUDE.includes(receipt.receiptStatus as ReceiptStatus)
        );

        const newUncommittedBalance = validReceipts
            .map((receipt) => new BigNumber(receipt.receiptAmount))
            .reduce((balance, receiptAmount) => balance.minus(receiptAmount), new BigNumber(lineAmount));

        return newUncommittedBalance.toNumber();
    }
}
